import React from 'react'
import styled from 'styled-components'
import { AboutButton } from '../Button/AboutButton'

const WannaKnowSection = styled.div `
  position:relative;
  /* background-color: ${props => props.theme.darkThemeBG}; */
  background-color: #191919;
  padding:5rem 15rem 5rem;
  & p{
    color: ${props => props.theme.paragraphColor};
    font-size:18px;
    line-height: 40px;
  }
  ${'' /* @media (max-width: 768px){
    padding: 5rem 2rem;
    & h1{
      font-size:35px;
    }
  } */}
  @media (max-width:1200px){
    padding:3rem 8rem;
  }
  @media (max-width:768px){
    padding:5rem 2rem;
  }
  @media (max-width:480px){
    padding:5rem 2rem 5rem;
  }
`;

const WannaKnowTitle = styled.h1 `
  margin:0px 0px 10px;
  font-size:40px;
  color: ${props => props.theme.titleColor};
`;

export const WannaKnow = () => {
  return (
    <WannaKnowSection>
      <WannaKnowTitle>Who am I? 🎈</WannaKnowTitle>
      <p>I possess a remarkable knack for transforming conceptual visions into tangible, successful solutions. I challenge assumptions, clarify ambiguities, and deeply empathize with users to engineer valuable products and delightful experiences.</p>
      <AboutButton/>
    </WannaKnowSection>
  )
}
