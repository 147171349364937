import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { WorkButton } from '../Button/WorkButton';

export const ProjectCard = ({id,name,description,preview_images}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  return (
    <div className="col-lg-4 col-md-6 p-0 px-2">
      <Card key={id} className="card m-0 mb-3">
        <PreviewImage src={Object.keys(preview_images)[0]} alt={name} className="card-img-top"/>
        <div className="card-body">
          <TitleProject>{name}</TitleProject>
          <DescriptionProject className="card-text">
            {showFullDescription ? description : `${description.slice(0, 80)}...`}
            <LinkViewMore onClick={toggleDescription} className="btn btn-link mainword p-0">
              {showFullDescription ? 'View less' : 'View more'}
            </LinkViewMore>
          </DescriptionProject>
          <Underline/>
          <Link to={`/detail/${id}`}>
            <WorkButton>View Project.. ➡️</WorkButton>
          </Link>
        </div>
      </Card>
    </div>
  )
}

const Underline = styled.hr `
  color: ${props => props.theme.titleColor};
`;

const Card = styled.div `
  background-color:#191919;
  margin:8px;
  padding:8px;
  box-shadow: rgba(87, 87, 87, 60) 0px 0.2rem 0.4rem;
  border-radius:5px;
`;
const LinkViewMore = styled.a`
  text-decoration:none;
  font-weight:500;
  color: ${props => props.theme.paragraphColor};
  transition: all 0.3s ease;
`;
const PreviewImage = styled.img `
  width:100%;
  height:200px;
  object-fit:cover;
`;
const TitleProject = styled.h1 `
  font-size:17px;
  color: ${props => props.theme.titleColor};
  text-align:left;
  margin-top:8px;
`;
const DescriptionProject = styled.p `
  font-size:15px;
  color: ${props => props.theme.paragraphColor};
  text-align:left;
  margin:0;
`;