import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
const Button = styled.button `
  padding: 15px;
  width:auto;
  color:black;
  background-color: ${props => props.theme.brandColor};
  border: 2px solid ${props => props.theme.brandColor};
  font-size:16px;
  font-weight:600;
  margin-top:10px;
  font-family: 'Space Mono', monospace;
  outline:none;
  &:hover{
    border:none;
    cursor: pointer;
    font-size:16px;
    background-color:transparent;
    transition: all ease 0.2s;
    color: ${props => props.theme.titleColor};
    border: 2px solid ${props => props.theme.titleColor};
  }
`;

export const AboutButton = () => {
  const history = useHistory();
  const handleHistory = (e) =>{
    e.preventDefault();
    history.push('/about');
  }

  useEffect(() => {
    console.log("Component mounted");
    window.scrollTo(0, 0);
  }, []);

  return (
    <Button onClick={handleHistory}>Read more ➡️</Button>
  )
}
