import React from 'react'
import styled from 'styled-components';
import LinkedinIcon from '../../assets/images/linkedin.png'
import GithubIcon from '../../assets/images/github.png'
import EmailIcon from '../../assets/images/email.png'

export const Home = () => {
  return (
    <HomeScreen>
      <WrapperTitle>
        <Title>
          Full Stack Developer <br/>
          Result Oriented, <br/>
          Critical Design.
        </Title>
        <p>
          I'm Hector, a Lima-based Full Stack Developer with over three years of expertise in crafting web applications from research to implementation.
        </p>
        <p>
          I've recently joined <a href="https://www.reservhotel.com/?ref=hectorsum.online"  rel="noopener noreferrer" target="_blank" className="mainword">ReservHotel</a>, where I develop and maintain web-based applications for clients abroad. In my spare time, I continue to hone my skills by creating web apps, ensuring I stay current in the ever-evolving tech landscape.
        </p>
        <SocialIconsWrapper className="col">
          <a href="https://github.com/hectorsum" target="_blank" rel="noopener noreferrer">
            <img src={GithubIcon} width="32" alt="Github"/>
          </a>
          <a href="mailto: sumhector@gmail.com" alt="Email to Hector Herrera">
            <img src={EmailIcon} width="32" alt="Mail"/>
          </a>
          <a href="https://linkedin.com/in/hector-herrera-cusi" target="_blank" rel="noopener noreferrer">
            <img src={LinkedinIcon} width="32" alt=""/>
          </a>
        </SocialIconsWrapper>
      </WrapperTitle>
      <Separator/>
    </HomeScreen>
  )
}

const SocialIconsWrapper = styled.div `
  display:flex;
  justify-content: flex-start;
  align-items:center;
  margin-top:2rem;
  & a{
    margin-right: 1.5rem; 
    text-decoration: none;
  }
  & a:hover{
    transition: all ease 0.3s;
    transform: translateY(-8%);
  }
`

const Separator = styled.hr `
  height: 6px;
  ${'' /* background-image: url('/project-images/divider.svg'); */}
  background-repeat: repeat-x;
  background-position: left center;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  background-color:red;
`;
const HomeScreen = styled.div `
  margin:0;
  padding:5rem 15rem 0;
  width:100%;
  height:100vh;
  display:flex;
  justify-content:center;
  @media (max-width:1200px){
    padding:5rem 8rem 0;
  }
  @media (max-width: 1024px){
    height: initial;
    padding-bottom:5rem;
  }
  @media (max-width:768px){
    padding:5rem 2rem 0;
  }
  @media (max-width:480px){
    padding:5rem 2rem 5rem;
  }
`;

const Title = styled.h1 `
  font-size: 4em;
  line-height:70px;
  color: ${props => props.theme.titleColor};
  font-family: 'Space Mono', monospace;
  letter-spacing: 0.01rem;
  margin:0px 0px 50px 0px;
  font-weight: 'bold';
  & span{
    color: ${props => props.theme.brandColor};
    font-family: 'Inconsolata', monospace;
    font-weight:800;
    font-size:72px;
  }
  @media (max-width: 1024px){
    font-size: 3.5em;
  }
  @media (max-width:768px){
    font-size: 3em;
  }
`;
const WrapperTitle = styled.div `
  ${'' /* position:absolute;
  height:auto;
  right:10%;
  top:25%; */}
  ${'' /* padding: 5rem; */}
  & p{
    font-size:1.3em;
    color: ${props => props.theme.paragraphColor};
    margin:0px;
    line-height:40px;
    margin-bottom:1rem;
  }
  & span, .mainword{
    text-decoration:none;
    font-weight:500;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.paragraphColor};
    color: ${props => props.theme.paragraphColor};
    transition: all 0.3s ease;
  }
  & .mainword:hover{
    color: ${props => props.theme.brandColor};
    border-bottom-color: ${props => props.theme.brandColor};
  }
  @media (max-width:768px){
    padding: 3rem 0rem;
    & p {
      font-size:1.2em;
    }
  }
  @media (max-width: 480px){
    position:relative;
    right:0%;
    top:10%;
    padding:0 0px;
    & h1{
      font-size:25px;
      line-height:40px;
    }
    & span{
      font-size:30px;
    }
    & p{
      font-size:18px;
    }
  }
  @media (max-width:320px){
    position:relative;
    right:-0%;
    top:0%;
    padding:0 16px;
  }
`;