import React from 'react'
import styled from 'styled-components';

const Button = styled.a `
  padding: 15px;
  width:fit-content;
  color:black;
  background-color: ${props => props.theme.brandColor};
  border: 2px solid ${props => props.theme.brandColor};
  font-size:16px;
  font-weight:600;
  margin-top:10px;
  font-family: 'Space Mono', monospace;
  outline:none;
  text-decoration: none;
  display:block;
  & a{
    text-decoration:none;
    color:black;
  }
  &:hover{
    cursor: pointer;
    font-size:16px;
    background-color:transparent;
    transition: all ease 0.2s;
    color: ${props => props.theme.titleColor};
    border: 2px solid ${props => props.theme.titleColor};
    & a{
      text-decoration:none;
      color:white;
    }
  }
`;


export const DownloadResume = () => {
  return (
      <Button href="https://docs.google.com/document/d/1ygs62_wUgEC-Z3rOHqCnliW5WKsmU1DddfyohnvZI58/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
        {/* <Button></Button> */}
        My Resume 📌
      </Button>
  )
}
