import React from 'react'
import styled from 'styled-components';
import { DownloadResume } from '../Button/DownloadResume';

export const About = () => {
  document.title = 'About | Hector Herrera'
  return (
    <AboutSection className="row m-0 mb-5 d-flex justify-content-center align-items-start ">
      <div className="d-flex justify-content-center">
        <ProfileImage className="mb-3 mt-3" src='/images/me.jpg' alt="Profile Image Hector Herrera"/>
      </div>
      <div className="d-flex justify-content-center">
        <Info> 
          <AboutTitle>about me 👨‍💻</AboutTitle>
          <p>Hello there!</p>
          <p>I'm Hector, you can call me "hectorsum", a dedicated Computer Systems Engineering graduate and Full Stack Professional Web Developer. Currently, I'm actively engaged in crafting web-based solutions for <a href="https://reservhotel.com/?ref=hectorsum.online"  rel="noopener noreferrer" target="_blank" className="mainword">ReservHotel</a> as a Software Developer.</p>
          <p>My journey in the realm of web development began after my stint at <a href="http://www.synopsis.ws/?ref=hector-herrera.herokuapp.com" rel="noopener noreferrer" target="_blank" className="mainword">Synopsis</a> as a Support Analyst, where I delved deep into the realm of Linux. Since then, I've been fervently exploring a myriad of technologies to enhance my repertoire and refine my craft. Witnessing people engage with and derive value from the applications I create brings me immense joy, and I'm always intrigued to hear their thoughts and experiences.</p>
          <p>I pride myself on being patient and meticulous, especially when embarking on new projects. My aim is to deliver nothing short of the finest user experience, paying meticulous attention to detail to ensure that my work positively impacts others. Moreover, I relish challenges and find fulfillment in imparting my knowledge of web development and other subjects to those eager to learn. It's an integral part of who I am and always will be.</p>
          <div className='row'>
            <div className='col-md-6 col-12'>
              <p className='mb-2'>When I'm not immersed in code, you can find me lost in reading books or spending quality time with my beloved feline companions. </p>
              <DownloadResume/>
            </div>
            <CustomImage className='col-md-6 col-12 w-fit' src='/images/gringo.jpeg' alt="Gringo Desk"/>
          </div>
        </Info>
      </div>
    </AboutSection>
  )
}

const AboutSection = styled.div `
  background-color: ${props => props.theme.darkThemeBG};
  position:relative;
  ${'' /* padding: 5rem; */}

  @media (max-width: 800px){
    ${'' /* padding: 5rem 0; */}
  }
`;
const Info = styled.div `
  display:flex;
  flex-direction:column;
  width:auto;
  padding: 0 20rem;
  & p{
    margin: 10px 0px;
    font-size:1.3em;
    line-height:40px;
    color: ${props => props.theme.paragraphColor};
  }
  & span, .mainword{
    text-decoration:none;
    font-weight:500;
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.paragraphColor};
    color: ${props => props.theme.paragraphColor};
    transition: all 0.3s ease;
  }
  & span:hover, .mainword:hover{
    color: ${props => props.theme.brandColor};
    border-bottom-color: ${props => props.theme.brandColor};
  }
  @media(max-width: 1200px){
    padding: 0 10rem;
  }
  @media(max-width: 1024px){
    padding: 0 8rem;
  }
  @media(max-width: 768px){
    padding: 0 5rem;
  }
  @media(max-width: 480px){
    padding: 0 0rem;
    & p{
      font-size:1.1em;
    }
  }
`
const ProfileImage = styled.img `
  width:550px;
  height:550px;
  max-width: 25%;
  max-height:25%;
  ${'' /* vertical-align:middle; */}
  object-fit:cover;
  object-position: 50% 50%;
  ${'' /* object-position:50% 50%;
  border-radius: 70% 30% 30% 70% / 70% 70% 30% 30%; */}
  border-radius:20px;
  filter: grayscale(50%);
  &:hover{
    filter: brightness(90%);
  }
  @media (max-width:800px){
    ${'' /* width: auto;
    height: auto; */}
    max-width: 100%;
    max-height:100%;
  }
`;
const CustomImage = styled.img `
  ${'' /* width: auto; */}
  object-fit:contain;
  border-radius:20px;
  filter: grayscale(50%);
  &:hover{
    filter: brightness(90%);
  }
`;
const AboutTitle = styled.h1 `
  margin:00px 0px 20px;
  font-size:40px;
  color: ${props => props.theme.titleColor};
  @media(max-width: 480px){
    font-size:30px;
  }
`;